import React, {useEffect} from "react";
import { Link } from "gatsby";
import Layout from "../layouts/Layout";
import SEO from "../components/seo";

const NotFoundPage = ({location}) => {

    console.log("WTF 404")

    useEffect(
        () => {
            localStorage.clear()
        }
    )
    return (
        <Layout location={location}>
          <SEO title="404 Not found" />
          <section className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-height-1-1 uk-light" data-uk-height-viewport="offset-top: true">
              <div className="uk-container uk-container-xsmall">
                  <div className="uk-padding">
                      <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                          <strong>&mdash;</strong> 404 This page does not exist
                      </p>
                      <h1 className="uk-margin-small-top">The easiest way to solve a problem is to deny it exists.</h1>
                  </div>
              </div>
          </section>
        </Layout>
    )
}

export default NotFoundPage
